import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BACKEND_MDM_URL, BACKEND_SCHEDULER_URL} from 'app/main/constants/serverConstants';
import {BUS_ENTITY_NOTE_COUNT, GET_BUSINESS_ENTITY_EX} from './urls';

export const getBusinessEntityEx = createAsyncThunk(
	'manageLocationsApp/getBusinessEntityEx',
	async (params, thunkApi) => {
	  try {
		const response = await axios.get(`${BACKEND_MDM_URL}${GET_BUSINESS_ENTITY_EX}`, { params });
		const data = await response.data;
		return data;
	  } catch (err) {
		return thunkApi.rejectWithValue(err);
	  }
	}
);

export const getBusEntityNoteCount = createAsyncThunk(
	'manageLocationsApp/getBusEntityNoteCount',
	async (params, thunkApi) => {
	  try {
		const response = await axios.get(`${BACKEND_SCHEDULER_URL}${BUS_ENTITY_NOTE_COUNT}`);
		const data = await response.data;
		return data;
	  } catch (err) {
		return thunkApi.rejectWithValue(err);
	  }
	}
);