import { MODULES } from "app/main/constants/taskBoardConstants";

/**
 * Authorization Roles
 */
const authRoles = {
	admin: 'admin',
	manager: 'manager',
	taskLead: 'task lead',
	technician: 'technician',
	staff: 'staff',
	analyst: 'analyst',
	user: 'user',
};

export const ownItemEditRoles = []

export default authRoles;

export function getPermissionForRole(role) {
	switch (role) {
		// Internal Roles
		case authRoles.manager:
		  return [
			{subject:MODULES.TASK_VIEW, action:["view", "create", "edit", "delete", "complete", "incomplete"]},
			{subject:MODULES.RECC_VIEW, action:["view", "create", "edit", "delete", "complete", "incomplete"]},
			{subject:MODULES.DAILY_PLANNER, action:["view", "create", "edit", "delete", "order", "complete", "incomplete"]},
			{subject:MODULES.SAMPLE_LOCATION, action:["view", "create", "edit"]},
		  ]
		case authRoles.taskLead:
		  return [
			{subject:MODULES.TASK_VIEW, action:["view", "create", "edit", "delete", "complete", "incomplete"]},
			{subject:MODULES.RECC_VIEW, action:["view", "create", "edit", "delete", "complete", "incomplete"]},
			{subject:MODULES.DAILY_PLANNER, action:["view", "create", "edit", "delete", "order", "complete", "incomplete"]},
			{subject:MODULES.SAMPLE_LOCATION, action:["view", "create", "edit"]},
		  ]
		case authRoles.technician:
		  return [
			{subject:MODULES.TASK_VIEW, action:["view", "complete", "incomplete"]},
			{subject:MODULES.RECC_VIEW, action:["view", "complete", "incomplete"]},
			{subject:MODULES.DAILY_PLANNER, action:["view", "complete", "incomplete"]},
			{subject:MODULES.SAMPLE_LOCATION, action:["view"]},
		  ]
		case authRoles.user:
		  return [
			{subject:MODULES.TASK_VIEW, action:["view", "complete", "incomplete"]},
			{subject:MODULES.RECC_VIEW, action:["view", "complete", "incomplete"]},
			{subject:MODULES.DAILY_PLANNER, action:["view", "complete", "incomplete"]},
			{subject:MODULES.SAMPLE_LOCATION, action:["view"]},
		  ]
		
		default:
		  return [
			{subject:MODULES.TASK_VIEW, action:["view",]},
			{subject:MODULES.RECC_VIEW, action:["view"]},
			{subject:MODULES.DAILY_PLANNER, action:["view"]},
			{subject:MODULES.SAMPLE_LOCATION, action:["view"]},
		  ]
	  }
}
