/* eslint-disable consistent-return */
import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {showMessage} from 'app/store/fuse/messageSlice';
import {BACKEND_SCHEDULER_URL} from 'app/main/constants/serverConstants';
import {CLIENTS_COMMENTS} from './urls';

export const getClientComments = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/getClientComments',
	async (params, thunkApi) => {
		try {
			const clientComments = (
				await axios.get(`${BACKEND_SCHEDULER_URL}${CLIENTS_COMMENTS}/${params.beId}/notes`)
			).data;
			return {beId: params.beId, notes: clientComments};
		} catch (err) {
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const addClientComments = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/addClientComments',
	async (params, thunkApi) => {
		try {
			const {beId, ...rest} = params;
			const comment = (await axios.post(`${BACKEND_SCHEDULER_URL}${CLIENTS_COMMENTS}/${beId}/notes`, rest)).data;
			thunkApi.dispatch(showMessage({message: 'Client Note created successfully', variant: 'success'}));
			return comment 
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err.message}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const updateClientComments = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/updateClientComments',
	async (params, thunkApi) => {
		try {
			const comment = (await axios.put(`${BACKEND_SCHEDULER_URL}${CLIENTS_COMMENTS}/${params.beId}/notes/${params.id}`, params)).data;
			thunkApi.dispatch(showMessage({message: 'Client Note updated successfully', variant: 'success'}));
			return comment
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err.message}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const deleteClientComments = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/deleteClientComments',
	async (params, thunkApi) => {
		try {
			await axios.delete(`${BACKEND_SCHEDULER_URL}${CLIENTS_COMMENTS}/${params.beId}/notes/${params.id}`);
			thunkApi.dispatch(showMessage({message: 'Client Note deleted successfully', variant: 'success'}));
		} catch (err) {
			thunkApi.dispatch(showMessage({message: `${err.message}`, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);
