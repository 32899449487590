import {ReoccurringSettings, ReoccurringTypes} from 'app/main/constants/taskBoardConstants';
import _ from 'lodash';
import moment from 'moment';
import {processTaskAddress} from './processTaskLocation';
import {checkIsAdminUser} from '../../utils/taskUtils';

export function getTaskAssignToValue(task, taskUsers) {
	let assignedToValue = [];
	if (!_.isEmpty(task?.thirdParty)) {
		assignedToValue.push({name: 'Third Party', id: undefined});
	}
	if (!_.isEmpty(task?.assignedTo) && !_.isEmpty(taskUsers)) {
		const assignUsers = taskUsers
			.filter(tUser => task?.assignedTo.includes(tUser.technicianUserId))
			.map(user => ({name: user.technicianFullName, id: user.technicianUserId}));
		assignedToValue = assignedToValue.concat(assignUsers);
	}
	return assignedToValue;
}

export const addTreeViewIds = (taskScheduleIds, newTask) => {
	// For Treeview
	const scheduleId = newTask.taskSheduleId;
	if (scheduleId) {
		if (taskScheduleIds[scheduleId]) {
			const taskId = taskScheduleIds[scheduleId];
			newTask.treeIds = [taskId, newTask.id];
		} else {
			taskScheduleIds[scheduleId] = newTask.id;
			newTask.treeIds = [newTask.id];
		}
	} else {
		newTask.treeIds = [newTask.id];
	}
	return newTask;
};

export function changeTaskGridDateFormat(dateToConvert) {
	if (dateToConvert) {
		// const due = moment.utc(dateToConvert).format('DD/MM/YYYY');
		// const today = moment.utc().format('DD/MM/YYYY');
		// if (due === today) return ' Today'; // Today text is only for filtering, adding space to show at the top
		// return moment.utc(dateToConvert).local().format('MM/DD/YYYY (ddd)');
		// return moment.utc(dateToConvert).format('YYYY/MM/DD');
		return moment.utc(dateToConvert).local().format('MM/DD/YYYY');
	}
	return dateToConvert;
}

const getLocRouteMap = (routes = []) => {
	const locRouteMap = {};
	_.forEach(routes, route => {
		_.forEach(route.routeLocations, loc => {
			if (_.has(locRouteMap, loc.id)) {
				locRouteMap[loc.id].add(route.routeName);
			} else {
				locRouteMap[loc.id] = new Set([route.routeName]);
			}
		});
	});
	return locRouteMap;
};

export const processTasks = (
	tasks,
	taskUsers,
	user,
	routes,
	sampleLocIdMap = {},
	businessEntityIdMap = {},
	locationIdMap = {},
) => {
	if (_.isEmpty(tasks)) return [];
	// set the client comment count to the response of get tasks
	let techInProgTask = {};
	const locRouteMap = getLocRouteMap(routes);
	const taskItems = ['Record Flow Rate'];
	const taskScheduleIds = {}; // For tree view
	const newTasks = _.map(tasks, task => {
		let newTask = _.cloneDeep(task);
		// task items for items section dropdown
		const items = _.flatten(newTask?.taskItems?.map(i => i?.itemName));
		_.forEach(items, item => {
			if (item) {
				const index = taskItems.findIndex(i => i.toLowerCase() === item.toLowerCase());
				if (index === -1) {
					taskItems.push(item);
				}
			}
		});
		const assignedToValue = getTaskAssignToValue(newTask, taskUsers);
		let assignedName = '';
		if (!_.isEmpty(assignedToValue)) {
			assignedName = assignedToValue.map(val => val.name);
			assignedName = assignedName.join(',');
		}

		const userData = user?.data?.userData || {};
		const isAdminUser = checkIsAdminUser(userData);

		addTreeViewIds(taskScheduleIds, newTask);

		const dueDateStr = changeTaskGridDateFormat(newTask.dueDate);
		const createdOnStr = changeTaskGridDateFormat(newTask.createdOn);
		const completedDateStr = changeTaskGridDateFormat(newTask.completedDate);

		// Route names for grid
		let routeNameSet = locRouteMap[newTask?.location?.id] || [];
		routeNameSet = [...routeNameSet];

		const actualDurationMin = (newTask?.actualDurationSec || 0) / 60;

		const sampleLocation = newTask?.sampleLocId ? sampleLocIdMap[newTask.sampleLocId] : undefined
		const location = sampleLocation?.locId ? locationIdMap[sampleLocation.locId] : undefined
		const busEntity = sampleLocation?.beId ? businessEntityIdMap[sampleLocation.beId] : undefined

		newTask = {
			...newTask,
			sampleLocation,
			location,
			busEntity,
			taskTypeDisplay: newTask.taskType + (newTask.taskSubType ? ` - ${newTask.taskSubType}` : ''),
			actualDurationMin: Number(actualDurationMin.toFixed(1)),
			thirdPartySelected: !_.isEmpty(newTask?.thirdParty),
			assignedToValue: getTaskAssignToValue(newTask, taskUsers),
			isTaskReoccurring: newTask.isRecurringTask,
			orderNo: newTask.orderNo || 1,
			// For Grid
			assignedName,
			createdOnStr,
			dueDateStr,
			completedDateStr,
			taskItemsStr: _.map(newTask.taskItems, i => i.itemName)?.join(','),
			locationAddr: processTaskAddress(location),
			reoccurringTask: newTask.isRecurringTask ? 'Yes' : 'No',
			routes: routeNameSet,
			durationFlag: !!newTask?.actualDurationSec,
			distanceFlag: !!newTask?.mileage,
		};
		// timer on/off show for technician level
		if (
			!isAdminUser &&
			newTask.actualStartDate &&
			!newTask.completedDate &&
			userData.email === newTask.statusUpdatedBy
		) {
			if (_.isEmpty(techInProgTask)) {
				techInProgTask = newTask;
			} else if (techInProgTask.dueDate < newTask.dueDate) {
				techInProgTask = newTask;
			}
		}
		return newTask;
	});
	return {
		tasks: newTasks,
		taskItems,
		techInProgTask
	};
};

export const processDataForMap = async (
	locationTasks,
	taskUsers=[],
	sampleLocIdMap = {},
	businessEntityIdMap = {},
	locationIdMap = {},
) => {
	const allTasks = _.map(locationTasks, task => {
		const assignedToValue = getTaskAssignToValue(task, taskUsers);
		let assignedName = '';
		if (!_.isEmpty(assignedToValue)) {
			assignedName = assignedToValue.map(val => val.name);
			assignedName = assignedName.join(',');
		}
		const dueDateStr = changeTaskGridDateFormat(task.dueDate);
		const sampleLocation = task?.sampleLocId ? sampleLocIdMap[task.sampleLocId] : undefined
		const location = sampleLocation?.locId ? locationIdMap[sampleLocation.locId] : undefined
		const busEntity = sampleLocation?.beId ? businessEntityIdMap[sampleLocation.beId] : undefined
		return {
			...task,
			assignedToValue,
			assignedName,
			dueDateStr,
			beName: busEntity?.shortName,
			latitude: location?.lngLat?.lat,
			longitude: location?.lngLat?.lat,
			locationName: location?.locName,
			sampleLocName: sampleLocation?.name,
			locationAddr: processTaskAddress(location),
		};
	});
	return allTasks
};

export const processReoccurringConfig = (
	tasks,
	taskUsers,
	sampleLocIdMap = {},
	businessEntityIdMap = {},
	locationIdMap = {},
) => {
	if (_.isEmpty(tasks)) return [];
	const newTasks = _.map(tasks, task => {
		let newTask = _.cloneDeep(task);
		const assignedToValue = getTaskAssignToValue(newTask, taskUsers);
		let assignedName = '';
		if (!_.isEmpty(assignedToValue)) {
			assignedName = assignedToValue.map(val => val.name);
			assignedName = assignedName.join(',');
		}

		const createdOnStr = changeTaskGridDateFormat(newTask.createdOn);
		const sampleLocation = newTask?.sampleLocId ? sampleLocIdMap[newTask.sampleLocId] : undefined
		const location = sampleLocation?.locId ? locationIdMap[sampleLocation.locId] : undefined
		const busEntity = sampleLocation?.beId ? businessEntityIdMap[sampleLocation.beId] : undefined

		newTask = {
			...newTask,
			sampleLocation,
			location,
			busEntity,
			taskTypeDisplay: newTask.taskType + (newTask.taskSubType ? ` - ${newTask.taskSubType}` : ''),
			thirdPartySelected: !_.isEmpty(newTask?.thirdParty),
			assignedToValue: getTaskAssignToValue(newTask, taskUsers),
			isTaskReoccurring: true,
			orderNo: newTask.orderNo || 1,
			// For Grid
			assignedName,
			createdOnStr,
			taskItemsStr: _.map(newTask.taskItems, i => i.itemName)?.join(','),
			locationAddr: processTaskAddress(location),
			reoccurringTask: 'Yes'
		};
		// For Reoccurring edit
		const reoccurringType = newTask.recurringType;
		delete newTask.recurringType;
		const reoccurringCount = newTask.recurringCount;
		delete newTask.recurringCount;
		const reoccurringFreq = newTask.frequency;
		delete newTask.frequency;
		const isReoccurringActive = newTask.isActive;
		delete newTask.isActive;
		const recAdditionalConfig = newTask.additionalConfig;
		delete newTask.additionalConfig;
		const reoccurringTypeFullText =
			reoccurringType === ReoccurringTypes.W
				? 'Weekly'
				: reoccurringType === ReoccurringTypes.M
					? 'Monthly'
					: reoccurringType === ReoccurringTypes.Y
						? 'Yearly'
						: '';

		newTask = {
			...newTask,
			// ...schedule,
			cronExpression: newTask.cronExpressionArr,
			taskScheduleId: newTask.id,
			reoccurringType,
			reoccurringTypeFullText,
			isReoccurringConfig: true,
			isReoccurringActive,
			isReoccurringActiveText: isReoccurringActive ? 'Yes' : 'No',
			reoccurringFreq,
			reoccurringCount,
			recAdditionalConfig,
			reoccurringEndType: newTask.endDate
				? ReoccurringSettings.DATE
				: reoccurringCount
					? ReoccurringSettings.COUNT
					: ReoccurringSettings.NOEND,
		};
		return newTask;
	});
	return newTasks;
};

export const processDeletedTask = (tasks, deletedTaskIds) => {
	const taskScheduleIds = {}; // For tree view
	const newTasks = _.filter(tasks, task => !deletedTaskIds.includes(task.id)).map(task =>
		addTreeViewIds(taskScheduleIds, task)
	);
	return newTasks;
};

export const processAttachments = attachments => {
	const newAttachments = [];
	_.forEach(attachments, item => {
		const index = _.findIndex(newAttachments, i => i.fileKey === item.fileKey);
		if (index === -1) {
			newAttachments.push({
				...item,
				createdBy: item.createdBy.replace(/<.*?>/g, '')
			});
		}
	});
	return newAttachments;
};

function extractEmailAddress(inputString) {
	const emailRegex = /<([^>]+)>/;
	const match = inputString.match(emailRegex);
	return match ? match[1] : null;
}

export const processTaskComments = comments => {
	const newComments = _.map(comments, comment => ({
		...comment,
		createdByName: comment.createdBy.replace(/<.*?>/g, ''),
		createdByEmail: extractEmailAddress(comment.createdBy)
	}));
	return newComments;
};
