import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {showMessage} from 'app/store/fuse/messageSlice';
import {BACKEND_MDM_URL} from 'app/main/constants/serverConstants';
import _ from 'lodash';
import {GET_SAMPLE_LOCATION_EX, GET_LOCATION_EX, GET_EQUIPMENT_EX, SAMPLE_LOC_WITH_ENTITIES} from './urls';
// eslint-disable-next-line import/no-cycle
import { setReloadGrid } from '../taskBoardGridSlice';
import { processSampleLocation } from './processTaskLocation';

export const createSLwithLocAndEquip = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/createSLwithLocAndEquip',
	async (params, thunkApi) => {
		try {
			const taskLocation = (await axios.post(`${BACKEND_MDM_URL}${SAMPLE_LOC_WITH_ENTITIES}`, params)).data;
			thunkApi.dispatch(showMessage({message: 'Location created Successfully', variant: 'success'}));
			return taskLocation;
		} catch (err) {
			let { message } = err || {}
			if (!_.isEmpty(err?.response?.data?.message)) {
				message = err.response.data.message
			}
			thunkApi.dispatch(showMessage({message, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const updateSLwithLocAndEquip = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/updateSLwithLocAndEquip',
	async (params, thunkApi) => {
		try {
			const response = await axios.post(`${BACKEND_MDM_URL}${SAMPLE_LOC_WITH_ENTITIES}`, params);
			const taskLocation = await response.data;
			thunkApi.dispatch(setReloadGrid());
			thunkApi.dispatch(showMessage({message: 'Location updated Successfully', variant: 'success'}));
			return taskLocation;
		} catch (err) {
			let { message } = err || {}
			if (!_.isEmpty(err?.response?.data?.message)) {
				message = err.response.data.message
			}
			thunkApi.dispatch(showMessage({message, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);

export const getSampleLocationEx = createAsyncThunk(
	'manageLocationsApp/getSampleLocationEx',
	async (params, thunkApi) => {
	  try {
		const response = await axios.get(`${BACKEND_MDM_URL}${GET_SAMPLE_LOCATION_EX}`, { params });
		const data = await response.data || [];
		const locationIdMap = thunkApi.getState()?.schedulerCommon?.schedulerCommon?.locationIdMap;
		return _.map(data, sl => processSampleLocation(sl, locationIdMap));
	  } catch (err) {
		return thunkApi.rejectWithValue(err);
	  }
	}
);

export const getLocationEx = createAsyncThunk(
	'manageLocationsApp/getLocationEx',
	async (params, thunkApi) => {
	  try {
		const response = await axios.get(`${BACKEND_MDM_URL}${GET_LOCATION_EX}`, { params });
		const data = await response.data;
		return data;
	  } catch (err) {
		return thunkApi.rejectWithValue(err);
	  }
	}
);

export const getEquipmentEx = createAsyncThunk(
	'manageLocationsApp/getEquipmentEx',
	async (params, thunkApi) => {
	  try {
		const response = await axios.get(`${BACKEND_MDM_URL}${GET_EQUIPMENT_EX}`, { params });
		const data = await response.data;
		return data;
	  } catch (err) {
		return thunkApi.rejectWithValue(err);
	  }
	}
);

export const updateLocationCoordinates = createAsyncThunk(
	'taskBoardApp/taskBoardGrid/updateLocationCoordinates',
	async (params, thunkApi) => {
		try {
			const location = (await axios.put(`${BACKEND_MDM_URL}/location/${params.id}/partial`, params)).data;
			thunkApi.dispatch(showMessage({message: 'Coordinates updated successfully', variant: 'success'}));
			return location;
		} catch (err) {
			let { message } = err || {}
			if (!_.isEmpty(err?.response?.data?.message)) {
				message = err.response.data.message
			}
			thunkApi.dispatch(showMessage({message, variant: 'error'}));
			return thunkApi.rejectWithValue('Something went wrong. Please try again!');
		}
	}
);