import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {showMessage} from 'app/store/fuse/messageSlice';
import {BACKEND_SCHEDULER_URL} from 'app/main/constants/serverConstants';
import techniciansService from './techniciansService';

const timestamp = new Date().getTime();

export const getTechnicians = createAsyncThunk(
	'requestAnalysisApp/requestAnalysis/getTechnicians',
	async (params, thunkApi) => {
		try {
			const response = await axios.get(`${BACKEND_SCHEDULER_URL}/api/remote/v3/technicians`, {
				params: {cached: false, t: timestamp}
			});
			const data = await response.data;
			return techniciansService.mapTechData(data);
		} catch (err) {
			console.warn(err);
			thunkApi.dispatch(showMessage({message: err.message, variant: 'error'}));
			return thunkApi.rejectWithValue(`Something went wrong. Please try again!`);
		}
	}
);
