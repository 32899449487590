import _ from 'lodash';

export const textSortFn = (a, b, field = null) => {
	const nameA = field ? a[field]?.toUpperCase() : a?.toUpperCase(); // ignore upper and lowercase
	const nameB = field ? b[field]?.toUpperCase() : b?.toUpperCase(); // ignore upper and lowercase

	// sort in an ascending order
	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}

	// names must be equal
	return 0;
};

export const processTaskAddress = locData => {
	if (!locData) return '';
	const data = [];
	const addrKeys = ['state', 'county', 'city', 'addrLine1', 'addrLine2'];
	_.forEach(addrKeys, key => {
		if (locData[key]) {
			data.push(locData[key]);
		}
	});
	return data.join(', ');
};


export const processSampleLocation = (sampleLoc, locationIdMap={}) => {
	const location = sampleLoc.locId && locationIdMap[sampleLoc.locId] ? locationIdMap[sampleLoc.locId] : undefined
	return {
		...sampleLoc,
		locationName: location?.locName	|| "No Location Found"
	}
}